/** @jsxImportSource @emotion/react */
import moment from 'moment/moment'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { StylesConfig } from 'react-select'
import { toast } from 'react-toastify'

import { formatPhoneNumber } from 'helpers/format'
import * as generalHelper from 'helpers/generalHelper'
import * as api from 'helpers/api'
import Select from 'components/atoms/Select'
import Input from 'components/atoms/Input'
import AsyncCreatableSelect from 'components/atoms/AsyncCreatableSelect'
import PayorDetailsModal from 'components/organisms/payer/PayerDetailsModal'
import CheckboxComponent from 'components/atoms/Checkbox'
import InputMask from '../../../atoms/InputMask'
import { TimeWrapper } from '../PsgHstWorkflow/FollowUpForm'
import LabPayorDetailsModal from './LabPayorDetailsModal'
import { PayerFormValues } from 'components/organisms/payer/PayerForm'
import { LinkLabPayerModal } from './LinkLabPayerModal'
import {
  useCreateLabPayerMutation,
  useLabCreateDmePayerMutation,
  useLabModifyPayerMutation,
  useLinkDmePayerToLabPayerMutation,
  useUpdateLabPayerMutation,
} from 'store/services/payer'
import { useSetOrderPriorityLevelMutation } from 'store/services/labOrder'
import { Payer } from 'store/types'
import { OrderVerifyStatus } from '../OrderEdit'

const selectStyles = {
  option: (styles: StylesConfig, { data }) => ({
    ...styles,
    fontWeight: data.value === '__new__' ? 'bold' : 'normal',
    textDecoration: data.value === '__new__' ? 'underline' : 'none',
    cursor: 'pointer',
  }),
}

interface IProps {
  orderId: number
  payers: Payer[]
  setPayers: (payers: Payer[]) => void
  officeId: string | number
  setFormDirty: (dirty: boolean) => void
  refetchOrder: () => void
  errors: { [position: string]: string }
  insuranceFormDirty?: boolean
  verification: OrderVerifyStatus
  isUnderVerification: boolean
  testType: string
  priorityLevel: number
}

export const PayerSelectionForm = ({
  orderId,
  payers,
  setPayers,
  officeId,
  setFormDirty,
  refetchOrder,
  errors,
  insuranceFormDirty,
  verification,
  isUnderVerification,
  testType,
  priorityLevel,
}: IProps) => {
  const [dmePayerDetailsModal, setPayerDetailsModal] = useState<
    (Partial<PayerFormValues> & { idx: number }) | null
  >()
  const [labPayerDetailsModal, setLabPayerDetailsModal] = useState<
    (Partial<PayerFormValues> & { idx: number }) | null
  >()
  const [payorCache, setPayorCache] = useState(1)
  const [linkLabPayerModal, setLinkLabPayerModal] = useState<{
    name: string
    id: number
    labPayerId?: number
  }>()
  const [labPayorLookup, setLabPayorLookup] = useState<{ labPayorId: number; dmePayorId: number }>()
  const [linkDmePayerToLabPayer] = useLinkDmePayerToLabPayerMutation()
  const [createDmePayer] = useLabCreateDmePayerMutation()
  const [modifyPayer] = useLabModifyPayerMutation()
  const [createLabPayer] = useCreateLabPayerMutation()
  const [updateLabPayer] = useUpdateLabPayerMutation()
  const [setOrderPriorityLevel] = useSetOrderPriorityLevelMutation()

  const loadPayers = async (inputValue: string) => {
    const data = await api.get(`/lab/dme-payers?officeId=${officeId}&name=${inputValue}`)
    setLabPayorLookup({
      ...labPayorLookup,
      ...data?.reduce((acc, curr) => {
        if (curr.labPayerId) acc[curr.id] = curr.labPayerId
        return acc
      }, {}),
    })

    return generalHelper.getLabelValue(data, 'id', 'name')
  }

  const getLabPayer = async (payerId: number) => {
    const data = await api.get(`/lab/payers/${payerId}`)
    return data
  }

  const addPayer = () => {
    setPayers(
      payers?.length < 3
        ? [
            ...payers,
            { id: '__new__', name: '', memberId: '', groupId: '', position: payers.length + 1 },
          ]
        : payers,
    )
    setFormDirty(true)
  }

  const removePayer = (idx: number) => {
    const newPayers = payers
      .filter((_, index) => index !== idx)
      .map((payer, index) => ({ ...payer, position: index + 1 }))
    if (!newPayers.length) {
      newPayers.push({ id: '__new__', name: '', memberId: '', groupId: '', position: 1 })
    }
    setPayers(newPayers)
    setFormDirty(true)
  }

  const replacePayer = (idx: number, payer: Payer) => {
    const newPayers = [...payers]
    newPayers[idx] = { ...payer, memberId: '', groupId: '', position: idx + 1 }
    setPayers(newPayers)
    setFormDirty(true)
  }

  const setPayerData = (idx: number, fields: { [key: string]: string }) => {
    const newPayers = [...payers]
    newPayers[idx] = { ...newPayers[idx], ...fields }
    setPayers(newPayers)
    setFormDirty(true)
  }

  const changePayerPosition = (idx: number, newPos: number) => {
    const newPayers = []
    for (let i = 0; i < Math.max(payers.length, newPos); i++) {
      if (i === newPos - 1) {
        newPayers.push({ ...payers[idx], position: newPos })
      } else if (i === idx) {
        if (!payers[newPos - 1]) {
          newPayers.push({ id: '__new__', name: '', memberId: '', groupId: '', position: i + 1 })
        } else {
          newPayers.push({ ...payers[newPos - 1], position: i + 1 })
        }
      } else {
        if (!payers[i]) {
          newPayers.push({ id: '__new__', name: '', memberId: '', groupId: '', position: i + 1 })
        } else {
          newPayers.push({ ...payers[i], position: i + 1 })
        }
      }
    }

    setPayers(newPayers)
    setFormDirty(true)
  }

  return (
    <div className="column">
      <LinkLabPayerModal
        open={!!linkLabPayerModal}
        dmePayorName={linkLabPayerModal?.name}
        labPayorId={linkLabPayerModal?.labPayerId}
        onClose={() => setLinkLabPayerModal(undefined)}
        onSubmit={async (labPayerId: number) => {
          await linkDmePayerToLabPayer({
            orderId,
            dmePayerId: linkLabPayerModal.id!,
            labPayerId,
          }).unwrap()
          setLinkLabPayerModal(undefined)
        }}
        onNewLabPayer={() => {
          setLabPayerDetailsModal({ name: '', dmePayerId: linkLabPayerModal?.id })
          setLinkLabPayerModal(undefined)
        }}
      />
      <LabPayorDetailsModal
        open={!!labPayerDetailsModal}
        onClose={() => setLabPayerDetailsModal(undefined)}
        payorDetails={labPayerDetailsModal}
        onSave={async (values) => {
          if (labPayerDetailsModal?.id) {
            await updateLabPayer({ id: labPayerDetailsModal.id, ...values })
          } else {
            const result = await createLabPayer(values).unwrap()
            await linkDmePayerToLabPayer({
              orderId,
              dmePayerId: labPayerDetailsModal?.dmePayerId!,
              labPayerId: result.id,
            }).unwrap()
          }
          refetchOrder()
          setLabPayerDetailsModal(undefined)
        }}
      />
      <PayorDetailsModal
        open={!!dmePayerDetailsModal}
        onClose={() => setPayerDetailsModal(undefined)}
        payorDetails={dmePayerDetailsModal}
        onSave={async (values) => {
          const result = await createDmePayer({ officeId: Number(officeId), ...values }).unwrap()
          console.log(result)
          replacePayer(dmePayerDetailsModal!.idx, { id: result.id, name: result.name })
          setPayerDetailsModal(undefined)
          setPayorCache((p) => p + 1)
        }}
      />
      <div className="col-12">
        <div className="row">
          {payers?.map((_, idx) => (
            <div className="col d-flex">
              {errors?.[idx + 1]?.name && (
                <div className="text-danger text-bold">{errors?.[idx + 1]?.name}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <CheckboxComponent
        label="STAT"
        checked={priorityLevel === 1}
        value={priorityLevel === 1}
        handleChange={async (e) => {
          if (e.target.checked) {
            await setOrderPriorityLevel({ orderId: orderId, priorityLevel: 1 }).unwrap()
          } else {
            await setOrderPriorityLevel({ orderId: orderId, priorityLevel: 4 }).unwrap()
          }
          toast.success(`Priority level updated: ${!e.target.checked ? 'STAT' : 'Normal'}`)
        }}
      />
      <div className="row">
        {payers?.map((payer, idx) => {
          const lock =
            testType !== 'PSG' &&
            (idx === 0
              ? verification.PAYER === 'verified'
              : verification[`PAYER_${idx + 1}`] === 'verified')
          return (
            <div className="col-md" css={{ flexDirection: 'column' }}>
              <fieldset disabled={lock}>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <Select
                    noDefault
                    wrapperClassName="m-0"
                    name="position"
                    handleChange={(e) => {
                      if (e.target.value === 'remove') {
                        removePayer(idx)
                      } else {
                        changePayerPosition(idx, Number(e.target.value))
                      }
                    }}
                    options={[
                      { key: '1', value: 'Primary Payor' },
                      { key: '2', value: 'Secondary Payor' },
                      { key: '3', value: 'Tertiary Payor' },
                      { key: 'remove', value: 'Remove Payor' },
                    ]}
                    value={payer.position}
                  />
                  {idx === payers?.length - 1 && idx !== 2 && isUnderVerification && (
                    <Button variant="link" onClick={addPayer} as="span">
                      Add Payor
                    </Button>
                  )}
                </div>
                <div className="d-flex pb-2 border-bottom" css={{ '& >div': { width: '100%' } }}>
                  {officeId && (
                    <AsyncCreatableSelect
                      disabled={lock}
                      createLabel="Create new payor"
                      cacheOptions={payorCache}
                      value={{ id: payer.id.toString(), label: payer.name }}
                      name="insurance_payer"
                      loadOptions={loadPayers}
                      styles={selectStyles}
                      handleNew={() => setPayerDetailsModal({ idx })}
                      handleChange={async (row) => {
                        if (!row) {
                          setPayerData(idx, { id: '', name: '' })
                        } else {
                          let labPayer
                          if (labPayorLookup?.[row.value]) {
                            labPayer = await getLabPayer(labPayorLookup?.[row.value])
                          }
                          setPayerData(idx, {
                            id: Number(row.value),
                            name: row.label,
                            ...(labPayer
                              ? {
                                  labPayerId: labPayer.id,
                                  labPayer,
                                }
                              : {
                                  labPayerId: null,
                                  labPayer: null,
                                }),
                          })
                        }
                      }}
                    />
                  )}
                </div>

                <div className="text-gray my-2">Payor</div>
                <div className="border-bottom pb-2">
                  {payer.labPayerId ? (
                    <>
                      <div className="d-flex justify-content-between pb-2 border-bottom align-items-center">
                        <div css={{ color: 'black' }}>
                          {payer?.labPayer?.name || payer?.labPayer?.names?.[0]?.name}
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => setLabPayerDetailsModal(payer.labPayer)}>
                            Edit Payor Info
                          </Button>

                          <Button
                            variant="outline-danger"
                            size="sm"
                            disabled={insuranceFormDirty}
                            onClick={() =>
                              setLinkLabPayerModal({
                                name: payer.name,
                                id: payer.id,
                                labPayerId: payer.labPayerId,
                              })
                            }>
                            Modify Link{insuranceFormDirty && ' (Save Form First)'}
                          </Button>
                        </div>
                      </div>
                      {payer.labPayer?.verificationLink && (
                        <>
                          <div className="text-gray">Verification Link</div>
                          <a
                            title={payer.labPayer.verificationLink}
                            href={
                              payer.labPayer.verificationLink.startsWith('http')
                                ? payer.labPayer.verificationLink
                                : `http://${payer.labPayer.verificationLink}`
                            }
                            target="_blank"
                            rel="noreferrer">
                            {/* {payer.labPayer.verificationLink} */}
                            Click Here
                          </a>
                        </>
                      )}
                      {payer.labPayer?.telephone && (
                        <>
                          <div className="text-gray mt-2">Payor Telephone Number</div>
                          <div>{formatPhoneNumber(payer.labPayer.telephone)}</div>
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      variant="link"
                      size="sm"
                      disabled={!payer.name || insuranceFormDirty}
                      onClick={() => setLinkLabPayerModal({ name: payer.name, id: payer.id })}>
                      Link Lab Payor{insuranceFormDirty && ' (Save Form First)'}
                    </Button>
                  )}
                </div>
                <div
                  className="p-1 pt-2 d-flex"
                  css={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
                  <label className="textGray" htmlFor="secondary_insurance_member_id">
                    Member ID
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    value={payer.memberId}
                    label={false}
                    placeholder=""
                    className="form-control"
                    name="memberId"
                    handleChange={(event) => {
                      setPayerData(idx, { memberId: event.target.value })
                    }}
                    error={errors?.[idx + 1]?.memberId}
                  />
                  <label className="textGray mt-2" htmlFor="groupId">
                    Group ID
                  </label>
                  <Input
                    value={payer.groupId}
                    placeholder=""
                    className="form-control"
                    name="groupId"
                    handleChange={(event) => {
                      setPayerData(idx, { groupId: event.target.value })
                    }}
                  />
                </div>
                <div className="row p-1 mb-2">
                  <div className="col-sm-6 d-flex" css={{ width: '100%' }}>
                    <TimeWrapper>
                      <label className="textGray mt-2" htmlFor="effectiveDate">
                        Effective Date
                      </label>
                      {/*<DateTimePicker*/}
                      {/*  value={payer.effectiveDate && new Date(payer.effectiveDate)}*/}
                      {/*  handleChange={(date) => {*/}
                      {/*    setPayerData(idx, { effectiveDate: moment(date).format('YYYY-MM-DD') })*/}
                      {/*  }}*/}
                      {/*  showTimeSelect={false}*/}
                      {/*  isClearable*/}
                      {/*  dateFormat="MMMM d, yyyy"*/}
                      {/*/>*/}
                      <InputMask
                        value={payer.effectiveDate ?? ' '}
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                        name="effectiveDate"
                        onChange={(event) => {
                          setPayerData(idx, { effectiveDate: event.target.value })
                        }}
                        error={errors?.[idx + 1]?.effectiveDate}
                        mask="99/99/9999"
                      />
                    </TimeWrapper>
                  </div>
                  <div className="col-sm-6 d-flex" css={{ width: '100%' }}>
                    <TimeWrapper>
                      <label className="textGray mt-2" htmlFor="terminationDate">
                        Termination Date
                      </label>
                      {/*<DateTimePicker*/}
                      {/*  value={payer.terminationDate && new Date(payer.terminationDate)}*/}
                      {/*  handleChange={(date) => {*/}
                      {/*    setPayerData(idx, { terminationDate: moment(date).format('YYYY-MM-DD') })*/}
                      {/*  }}*/}
                      {/*  showTimeSelect={false}*/}
                      {/*  isClearable*/}
                      {/*  dateFormat="MMMM d, yyyy"*/}
                      {/*/>*/}
                      <InputMask
                        value={payer.terminationDate ?? ' '}
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                        name="terminationDate"
                        onChange={(event) => {
                          setPayerData(idx, { terminationDate: event.target.value })
                        }}
                        error={errors?.[idx + 1]?.terminationDate}
                        mask="99/99/9999"
                      />
                    </TimeWrapper>
                  </div>
                </div>
              </fieldset>
            </div>
          )
        })}
      </div>
    </div>
  )
}
